.body{
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  font-family: Comfortaa;
  /* background-color: #383838; */
  flex-direction: column;
  padding-top: 12px;
}

.red {
  color:red;
}


.nav {
  height: 55px;
  width: calc(100vw - 24px);
  /* width: 100vw; */
  border-radius: 10px;
  background-color: #e9f3ff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav .logo {
  height: 45px;
}

.nav .navigation{
  width: 60%;
}

.nav ul{ 
  /* font-size: 20px; */
  font-weight: bold;
  padding: 0px 24px;
  width: 100%;
  justify-content:end;
  align-items: center;
  list-style: none;
}


.nav li{
  
/* box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.458); */
/* padding: 8px; */
border-top:  4px solid rgba(0, 0, 255, 0);
border-bottom: 4px solid rgba(0, 0, 255, 0);
}

.large-menu{
  display: flex;
  gap: 12px;
}

.mini-menu{
  display: none;
  gap: 20px;
}

.mini-menu img{
  width: 32px;
}



.main {
  width: 100%;
  min-height: calc(100vh - 100px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 50px;
  padding: 50px 24px;
}

.content{
width: 750px;
/* height: 500px; */
border-radius: 14px;
padding: 16px;
box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.458);
background-color: #fff;

}

.info {
  width: 100%;
  display: flex;
  justify-content: space-around;
  border-radius:8px;
  background-color: #e9f3ff;
  /* background-color: #edf5ff; */
}

.info span{
  padding: 16px 16px;
  text-align: center;
  font-weight: 900;
  font-size: 20px;
}

.s1, .s3{
  width: 33%;

}
.s2{
  width: 20%;

}


.value{
  width: 100%;
  padding: 24px 48px;
  font-size: 20px;
  word-break: break-all;
}
.show {
  width: 100%;
  padding: 8px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius:8px;
  background-color: #edf5ff;
}

.show span{
  text-align: center;
  font-weight: 700;
  font-size: 20px;
  width: 50%;
}

.show button{
  text-align: center;
  border: none;
  padding: 16px;
  background-color: rgb(50, 122, 254);
  color: #fff;
  border-radius: 500px;
}

.show button:focus{
  border: none;
  outline: none;
}


.keyboard{
  padding-top: 20px;
  width: 200px;
  height: 250px;
  /* background-color: aqua; */
  display: none; 
  flex-wrap: wrap;
  /* align-items: center; */
  justify-content: center; 
  gap: 10px;
}

.key{
  width: 50px; 
  height: 50px; 
  background-color: #e9f3ff;
  text-align: center; 
  line-height: 50px;
  border-radius: 5px;
}


.type-two .input-area {
  display: flex;

}


@media (hover: hover) {

  .nav li:hover{
    
  /* box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.458); */
  /* padding: 8px; */
  border-bottom: 4px solid rgb(50, 122, 254);
  cursor: pointer;
  }

  .key:hover{
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.458);
    cursor: pointer;
  }

}


@media (max-width: 1000px) {
  
  /* .main{

    gap: 0px;
  } */

  .keyboard {
    display: flex;
  }
}

@media (max-width: 500px) {
  .mini-menu{
    display: flex;
  }

  .large-menu{
    display: none;
  }
}